<template>
  <div class="main-content">
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>Loading event detail...</p>
    </div>
    <div v-else-if="event">
      <div class="fixed-header column">
        <BackToPageLink
          title="Back To Dashboard"
          @close="$router.push({ name: '/dashboard' })" />
      </div>
      <Card
        flexDirection="column"
        :flex="12"
        className="full baseline service-detail-config mb_1">
        <template v-slot:content>
          <div class="service-details column mb_1">
            <div class="row">
              <DetailConfigLabelAndInput
                label="Event Date"
                :value="event.event.date" />

              <DetailConfigLabelAndInput
                label="Event Name"
                :value="event.event.name" />
            </div>

            <div v-if="event.services" class="input-wrapper row">
              <DetailConfigLabelAndInput
                label="Start Time"
                :value="getServiceTime(event.services, 'startTime', true)" />
              <DetailConfigLabelAndInput
                label="End Time"
                :value="getServiceTime(event.services, 'endTime', true)" />
            </div>
          </div>
        </template>
      </Card>

      <div class="sections row baseline">
        <OrdersAndRevenueOverview
          :services="event.services"
          :operator-id="operatorId"
          :operator-type="operatorType" />
        <SalesOverview :service="event" :event-date="event.event.date" />
        <TransactionsTable :page-data="event.event" page-data-type="event" />
        <OrdersTimeLineChart :services="event.services" />
      </div>
    </div>
  </div>
</template>

<script>
  import store from "@/store";

  // components
  import LoadingSpinner from "@/components/LoadingSpinner";
  import Card from "@/components/Card.vue";
  import BackToPageLink from "@/components/BackToPageLink.vue";
  import OrdersAndRevenueOverview from "@/components/EventDetails/OrdersAndRevenueOverview.vue";
  import TransactionsTable from "@/components/TransactionsTable.vue";
  import SalesOverview from "@/components/SalesOverview.vue";
  import DetailConfigLabelAndInput from "@/components/EventDetails/DetailConfigLabelAndInput.vue";
  import OrdersTimeLineChart from "@/components/EventDetails/OrdersTimelineChart.vue";

  // helpers
  import { getServiceTime } from "@/helpers/service.js";

  export default {
    components: {
      LoadingSpinner,
      Card,
      BackToPageLink,
      OrdersAndRevenueOverview,
      TransactionsTable,
      SalesOverview,
      DetailConfigLabelAndInput,
      OrdersTimeLineChart,
    },

    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        event: this.$route.params.event
          ? JSON.parse(this.$route.params.event)
          : null,
        loading: false,
        getServiceTime,
      };
    },

    methods: {
      async fetchEvent() {
        this.loading = true;
        const eventId = this.$route.params.eventId;
        await store.state.apiPublic.client.endpoints.events
          .getbyIdWithQuery({ includeServices: true }, eventId)
          .then((response) => {
            const event = response?.data?.data;
            if (event) this.event = event;
          })
          .finally(() => (this.loading = false));
      },
    },

    async beforeMount() {
      if (!this.event) await this.fetchEvent();
    },
  };
</script>

<style lang="scss">
  .fixed-header {
    margin-bottom: 10px;
  }

  .sections {
    section {
      margin: 0;
    }
  }
</style>
