<template>
  <div class="timeline-chart">
    <div class="centered-loading-wrapper" v-if="loading">
      <LoadingSpinner />
    </div>
    <Card v-else :flex="12" title="Timeline for Order & Revenue">
      <template v-slot:content>
        <section>
          <HeatMap
            :data="orders"
            total-width="900"
            :margin="{ top: 30, right: 30, bottom: 30, left: 150 }"
            :color-range="['#eee', '#f85959']"
            :tooltip-value="tooltip"
            row-label-name="time"
            column-label-name="vendorName"
            value-name="value" />
        </section>
      </template>
    </Card>
  </div>
</template>

<script>
  import store from "@/store";

  import HeatMap from "@/components/charts/HeatMap";
  import Card from "@/components/Card.vue";
  import LoadingSpinner from "@/components/LoadingSpinner";

  export default {
    components: {
      Card,
      LoadingSpinner,
      HeatMap,
    },
    data() {
      return {
        orders: [],
        loading: false,
        serviceId: this.$route.params.eventId,
        tooltip: [
          {
            title: "Number of orders:",
            fieldName: "value",
          },
          {
            title: "Revenue:",
            fieldName: "revenue",
          },
        ],
      };
    },

    props: {
      services: {
        type: Object,
      },
    },
    methods: {
      async fetchData() {
        const serviceIds = this.services.map((service) => service.id);
        this.loading = true;

        await store.state.apiPrivate.client.endpoints.serviceBusyness
          .get({ serviceIds: serviceIds })
          .then((response) => {
            response.data.data.map((busyness) => {
              const vendor = this.services.find(
                (service) => service.id === busyness.id
              );
              let orders = busyness.busyness;

              // put 00:00 at the end of list
              orders.push(orders.shift());

              orders.forEach((order) => {
                this.orders.push({
                  vendorName: vendor.vendor.name,
                  time: this.getHour(order.timeFrom),
                  value: order.totalSales,
                  revenue: this.formatMoney(order.totalRevenue),
                });
              });
            });
          })
          .finally(() => (this.loading = false));
      },
      getHour(data) {
        let hour = new Date(data).getHours();
        const suffix = hour >= 12 ? "pm" : "am";

        return ((hour + 11) % 12) + 1 + suffix;
      },
    },

    async beforeMount() {
      await this.fetchData();
    },
  };
</script>

<style scoped lang="scss">
  .timeline-chart {
    width: 100%;
  }
</style>
