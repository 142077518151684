<template>
  <div id="heatmap"></div>
</template>

<script>
  import * as d3 from "d3";

  export default {
    data() {
      return {
        // maxNumberOfOrders: 20,
        maxNumberOfOrders: Math.max(...this.data.map((o) => o.value)) + 1,
      };
    },

    props: {
      data: {
        type: Object,
      },
      rowLabelName: {
        type: String,
      },
      columnLabelName: {
        type: String,
      },
      valueName: {
        type: String,
      },
      totalWidth: {
        type: String,
      },
      margin: {
        type: Object,
      },
      colorRange: {
        type: Object,
      },
      tooltipValue: {
        type: Object,
      },
    },

    computed: {
      width() {
        return this.totalWidth - this.margin.left - this.margin.right;
      },
      height() {
        return (this.width / this.myVars.length) * this.myGroups.length;
      },
      myVars() {
        return this.getAxisData(this.rowLabelName);
      },
      myGroups() {
        return this.getAxisData(this.columnLabelName);
      },
    },

    methods: {
      getAxisData(name) {
        const data = [];
        this.data.forEach((element) => {
          if (data.indexOf(element[name]) === -1)
            return data.push(element[name]);
        });
        return data;
      },
    },

    mounted() {
      // append the svg object to the body of the page
      const svg = d3
        .select("#heatmap")
        .append("svg")
        .attr("width", this.width + this.margin.left + this.margin.right)
        .attr("height", this.height + this.margin.top + this.margin.bottom)
        .append("g")
        .attr(
          "transform",
          "translate(" + this.margin.left + "," + this.margin.top + ")"
        );

      // Build X scales and axis:
      const x = d3
        .scaleBand()
        .range([0, this.width])
        .domain(this.myVars)
        .padding(0.01);
      svg
        .append("g")
        .attr("transform", "translate(0," + this.height + ")")
        .style("font-size", "12px")
        .call(d3.axisBottom(x));

      // Build X scales and axis:
      const y = d3
        .scaleBand()
        .range([this.height, 0])
        .domain(this.myGroups)
        .padding(0.01);
      svg.append("g").call(d3.axisLeft(y)).style("font-size", "12px");

      // Build color scale
      const myColor = d3
        .scaleLinear()
        // .exponent(0.454545454545) // mapping linear data onto a sRGB color curve requires gamma to be unapplied
        .range(this.colorRange)
        .domain([0, this.maxNumberOfOrders]);

      // create a tooltip
      const tooltip = d3
        .select("#heatmap")
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip");

      // Three function that change the tooltip when user hover / move / leave a cell
      const mouseover = (event) => {
        tooltip.style("opacity", 1);
        d3.select(event.target).style("filter", "saturate(5)");
      };
      const mousemove = (event) => {
        const output = this.tooltipValue.reduce(
          (str, item) =>
            (str += `${item.title} <span class='value'>${
              event.target.__data__[item.fieldName]
            }</span> <br>`),
          ""
        );

        tooltip
          .html(output)
          .style("left", event.clientX - 60 + "px")
          .style("top", event.clientY - 80 + "px");
      };
      const mouseleave = (event) => {
        tooltip.style("opacity", 0);
        d3.select(event.target).style("filter", "none");
      };

      // add the squares
      svg
        .selectAll()
        .data(this.data, (d) => {
          return d[this.rowLabelName] + ":" + d[this.columnLabelName];
        })
        .enter()
        .append("rect")
        .attr("x", (d) => {
          return x(d[this.rowLabelName]);
        })
        .attr("y", (d) => {
          return y(d[this.columnLabelName]);
        })
        .attr("width", x.bandwidth() - 1.5)
        .attr("height", y.bandwidth() - 1.5)
        .style("fill", (d) => {
          return myColor(d[this.valueName]);
        })
        .attr("rx", 3)
        .on("mouseover", mouseover)
        .on("mousemove", mousemove)
        .on("mouseleave", mouseleave);
    },
  };
</script>

<style lang="scss">
  .tooltip {
    min-width: 160px;
    max-width: max-content;
    position: fixed;
    padding: $input_padding;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    background: #fff;
    line-height: 1.5em;
    .value {
      font-weight: $card_font_weight;
      color: $col_alpha;
    }
  }
  #heatmap {
    position: relative;
  }
</style>
