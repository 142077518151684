<template>
  <Card :flex="4" title="Services" class="sales-summary-wrapper">
    <template v-slot:content>
      <section class="service-overview">
        <div class="services column no-gap">
          <div v-if="services.length === 0">No services</div>
          <div
            v-else
            class="service row"
            v-for="item in services"
            :key="item.id"
            @click="handleGoToPage(item.id)">
            <div class="info column no-gap">
              <div class="date row">
                <p class="row no-gap">
                  <span class="dayname">{{ item.vendor.name }}</span>
                </p>
              </div>
              <div class="service__time row">
                <Icon :path="mdiClock" :size="14" />
                <span>{{ item.startTime }} - {{ item.endTime }}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </Card>
</template>

<script>
  import Card from "@/components/Card.vue";
  import { mdiClock } from "@mdi/js";

  export default {
    components: {
      Card,
    },
    data() {
      return {
        mdiClock,
      };
    },
    props: {
      services: {
        type: Object,
      },
      operatorId: {
        type: Number,
      },
      operatorType: {
        type: String,
      },
    },
    methods: {
      handleGoToPage(id) {
        const eventId = this.$route.params.eventId;

        window.localStorage.setItem("eventId", eventId);
        this.$router.push({
          path: `/${this.operatorType}/${this.operatorId}/service/${id}`,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .service-overview {
    width: 100%;
  }
  .services {
    .service {
      cursor: pointer;
      border-top: 1px solid #ddd;
      background: #fff;
      padding: 0.8rem 0.5rem;

      &:hover {
        background: $col_beta-lightest;
      }

      .row {
        margin: 0;
      }

      &__time {
        font-size: 0.8rem;
        color: #999;
        margin-top: 5px !important;
        gap: 5px;
      }

      &__icon {
        font-size: 0.8rem;
      }
    }
  }
</style>
